<style lang="less" scoped>
.title {
  padding: 30px 0 10px 20px;
}
.footer {
  padding-top: 30px;
}
.cate-content {
  padding-left: 90px;
  .bland {
    padding-top: 10px;
    .first-cate {
      padding-left: 154px;
      padding-top: 10px;
      &:nth-of-type(1) {
        margin-top: -18px;
        padding-top: 0 !important;
      }
      &:last-child {
        .second-cate {
          border-bottom: none;
        }
      }
      .second-cate {
        // padding-bottom: 10px;
        padding-left: 174px;
        padding-right: 40px;
        p:nth-of-type(1) {
          margin-top: -22px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/role/list' }"
        >角色列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{
        info.id ? "修改角色" : "新增角色"
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix">
      <el-form ref="editForm" :model="info" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="info.name"></el-input>
        </el-form-item>

        <el-form-item label="编码" prop="code">
          <el-input :disabled="isEdit" v-model="info.code"></el-input>
        </el-form-item>

        <el-form-item label="描述" prop="remarks">
          <el-input type="textarea" v-model="info.remarks"></el-input>
        </el-form-item>
      </el-form>

      <div class="title">权限设置</div>

      <div class="cate-content">
        <div v-for="(brand, index) in permissionList" class="bland" :key="index">
          <el-checkbox @change="changeBrand(brand)" v-model="brand.isAuth">
            {{ brand.name }}
          </el-checkbox>
          <template v-if="brand.children">
            <div
              v-for="(firstCate, firstCateIndex) in brand.children"
              class="first-cate"
              :key="firstCateIndex"
            >
              <el-checkbox
                @change="changeFirstCate(firstCate, brand)"
                v-model="firstCate.isAuth"
              >
                {{ firstCate.name }}
              </el-checkbox>
              <div class="second-cate">
                <template v-if="firstCate.children">
                  <p v-for="(secondCate, secondCateIndex) in firstCate.children" :key="secondCateIndex">
                    <el-checkbox
                      @change="changeSecond(secondCate, firstCate, brand)"
                      v-model="secondCate.isAuth"
                    >
                      {{ secondCate.name }}
                    </el-checkbox>
                  </p>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>

      <section class="footer">
        <el-button
          style="margin-left: 50px"
          type="primary"
          @click="updateGroupInfo"
          >确定</el-button
        >
        <el-button @click="$router.go(-1)">取消</el-button>
      </section>
    </div>
  </div>
</template>

<script>
import {
  getAllPermissions,
  createRole,
  updateRole,
  getRoleById,
  getRolePms,
} from "@/services/role";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isEdit: false,
      permissionList: [],
      info: {
        name: "",
        code: "",
        remarks: "",
        permissionList: "",
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },

  computed: {
    ...mapGetters({
      user: "userInfo",
    }),
  },

  methods: {
    // 点击一级
    handleCheckAll(row) {
      if (row.isAuth) {
        row.children.forEach((item) => {
          item.isAuth = true;
        });
      } else {
        row.children.forEach((item) => {
          item.isAuth = false;
        });
      }
    },

    // 点击二级
    handleCheckChild(row, child) {
      if (row.children && row.children.length) {
        const checkList = row.children.filter((item) => item.isAuth === true);
        const checkCount = checkList.length;
        row.isAuth = checkCount > 0;
      }
    },

    // 改变品牌
    changeBrand(brand) {
      if (!brand.children) return;
      if (brand.isAuth) {
        brand.children.forEach((item) => {
          item.isAuth = true;
          if (item.children) {
            item.children.forEach((it) => {
              it.isAuth = true;
            });
          }
        });
      } else {
        brand.children.forEach((item) => {
          item.isAuth = false;
          if (item.children) {
            item.children.forEach((it) => {
              it.isAuth = false;
            });
          }
        });
      }
    },

    // 改变一级分类
    changeFirstCate(firstCate, brand) {
      if (firstCate.isAuth) {
        firstCate.children.forEach((item) => {
          item.isAuth = true;
        });
        brand.isAuth = true;
      } else {
        firstCate.children.forEach((item) => {
          item.isAuth = false;
        });
        if (!brand.children.some((item) => item.isAuth)) {
          brand.isAuth = false;
        }
      }
    },

    // 改变二级分类
    changeSecond(secondCate, firstCate, brand) {
      if (secondCate.isAuth) {
        firstCate.isAuth = true;
        brand.isAuth = true;
      } else {
        // if (!firstCate.children.some(item => item.isAuth)) {
        //   firstCate.isAuth = false;
        //   if (!brand.children.some(item => item.isAuth)) {
        //     brand.isAuth = false;
        //   }
        // }
      }
    },

    // 角色详情
    async queryGroupDetail() {
      this.loading = true;
      const responseData = await getRoleById(this.$route.query.id);
      if (responseData.errorCode === 0) {
        this.info = responseData.data || {};
      }
      this.loading = false;
    },

    // 角色权限
    async queryGroupPowers() {
      this.loading = true;
      const responseData = await getRolePms(this.$route.query.id);
      if (responseData.errorCode === 0 && responseData.data) {
        this.permissionList = responseData.data;
      }
      this.loading = false;
    },

    // 获取所有权限
    async queryAllPermission() {
      this.loading = true;
      const responseData = await getAllPermissions();
      if (responseData && responseData.errorCode === 0 && responseData.data) {
        this.permissionList = responseData.data;
      }
      this.loading = false;
    },

    // 新建或修改用户组
    updateGroupInfo() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          let responseData = null;
          this.info.permissionList = JSON.stringify(this.permissionList);

          if (this.isEdit) {
            responseData = await updateRole(this.info);
          } else {
            delete this.info.id;
            responseData = await createRole(this.info);
          }
          if (responseData.errorCode === 0) {
            this.$notify.success({
              title: "成功",
              message: this.isEdit ? "修改成功" : "新增成功",
            });
            window.router.go(-1);
          }
        }
      });
    },
  },

  created() {
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id;
      this.queryGroupDetail();
      this.queryGroupPowers();
      this.isEdit = true;
    } else {
      this.queryAllPermission();
      this.isEdit = false;
    }
  },
};
</script>
