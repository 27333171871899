<style lang="less" scoped></style>

<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }">
        用户管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-on:keyup.13="handleSearch">
      <!--工具条-->
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input
            style="width: 240px"
            clearable
            v-model="filters.name"
            placeholder="根据名称筛选"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="roleList"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column
          :selectable="
            (row) => {
              return !row.system;
            }
          "
          type="selection"
          width="35"
        ></el-table-column>
        <el-table-column align="center" label="序号" min-width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          min-width="200"
          sortable
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="描述"
          class-name="el-column-ellipsis"
          min-width="200"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="150"
          v-if="user.roleCode === 'admin'"
        >
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              type="danger"
              class="m-l-xs"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button type="primary" @click="addRole">新增角色</el-button>
        <el-button
          v-if="user.roleCode === 'admin'"
          type="danger"
          @click="batchRemove"
          :disabled="this.sels.length === 0"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :title="isEdit ? '修改角色详情' : '新增角色详情'"
      size="small"
      :visible.sync="roleDetailVisible"
    >
      <role-view
        :defaultRoleInfo="choosedRole"
        :roleDetailVisible="roleDetailVisible"
        :isEdit="isEdit"
        :defaultPmtIds="permissionIds"
        v-on:onSubmit="roleDetailSubmit"
      ></role-view>
    </el-dialog>
  </div>
</template>
<script>
  import { getAllRole, removeRole, getRolePms } from "../../../services/role";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../../config";
  import RoleView from "./detail.vue";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        isEdit: false,
        fetchRoleList: [],
        filterRoleList: [],
        roleList: [],
        filters: {
          name: "",
        },
        PAGE_SIZE,
        pager: {
          page: 1,
          size: PAGE_SIZE,
        },
        total: 0,
        listLoading: false,
        roleDetailVisible: false,
        choosedRowIndex: 0,
        choosedRole: {
          id: "",
          name: "",
          code: "",
          remarks: "",
          roleList: [],
        },
        sels: [], //列表选中列
        permissionIds: [],
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    components: {
      "role-view": RoleView,
    },

    methods: {
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeRole(row.id).then((res) => {
              this.listLoading = false;
              this.fetchRoleList.splice(
                this.pager.size * this.pager.page + index,
                1
              );
              this.refreshRoleList();
              Notification.success({
                title: "成功",
                message: "删除成功",
              });
              this.getList();
            });
          })
          .catch(() => {});
      },

      /**
       * 删除，新增、修改后重新刷新目录list
       * @returns {Promise.<void>}
       */
      refreshRoleList() {
        this.filterRoleList = this.fetchRoleList.filter((item) => {
          return item.name.includes(this.filters.name);
        });
        this.roleList = this.filterRoleList.slice(
          this.pager.page * this.pager.size,
          (this.pager.page + 1) * this.pager.size
        );
      },
      /**
       * 点击新增初始化
       */
      addRole() {
        this.$router.push({
          path: "/user/role/detail",
        });
      },
      /**
       * 跳转到编辑
       */
      async handleEdit(index, row) {
        this.$router.push({
          path: "/user/role/detail",
          query: {
            id: row.id,
          },
        });
        // this.choosedRowIndex = index;
        // this.isEdit = true;
        // this.permissionIds = [];
        // this.choosedRole = Object.assign({}, row);
        // const res = await getRolePms(this.choosedRole.id);
        // if (res.errorCode === 0) {
        //   const list = res.data || [];
        //   list.forEach(
        //     function (n) {
        //       this.permissionIds.push(n.id);
        //     }.bind(this)
        //   );
        // }

        // this.roleDetailVisible = true;
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.getList();
      },
      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryDirectorys();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.getList();
        }
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeRole(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              Notification.success({
                title: "成功",
                message: "批量删除成功",
              });
              this.filterRoleList = []; //批量删除的时候，去做服务器数据刷新
              this.getList();
            });
          })
          .catch(() => {});
      },

      handleSearch() {
        this.pager.page = 1;
        this.getList();
      },

      /**
       * 获取所有的角色
       */
      async getList() {
        this.listLoading = true;
        const responseData = await getAllRole(this.filters, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.roleList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       *用户修改或者新增成功
       * @param isCreate true 创建  false 修改
       */
      roleDetailSubmit(roleData) {
        const roleInfo = Object.assign({}, roleData);
        if (this.isEdit) {
          this.roleList.splice(this.choosedRowIndex, 1, roleInfo);
        } else {
          this.total++;
          this.roleList.push(roleInfo);
          if (this.pager.page === Math.floor(this.total / this.pager.size)) {
            if (this.total % PAGE_SIZE === 1) {
              this.pager.page++;
            }
          }
        }
        this.$store.dispatch("refreshRoles", this.roleList);
        this.roleDetailVisible = false;
      },
    },
    created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }

      this.getList();
    },

    watch: {
      roleDetailVisible(newVal) {
        if (newVal === false) {
          this.isEdit = false;
          this.choosedRole = {
            id: "",
            name: "",
            code: "",
            desc: "",
            roleList: [],
          };
        }
      },
    },
  };
</script>
